<template><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enable-background="new 0 0 64 64" :width="width" :height="height"><g :fill="fill"><path d="M20.5 28.6c6.1 0 11.1-4.8 11.1-10.8S26.6 7 20.5 7 9.4 11.8 9.4 17.8s5 10.8 11.1 10.8zm0-18.1c4.2 0 7.6 3.3 7.6 7.3s-3.4 7.3-7.6 7.3-7.6-3.3-7.6-7.3 3.4-7.3 7.6-7.3z"></path><path d="M46.5 33.5c5.2 0 9.5-4.1 9.5-9.2s-4.3-9.2-9.5-9.2-9.5 4.1-9.5 9.2c0 5 4.3 9.2 9.5 9.2zm0-15c3.3 0 6 2.6 6 5.7 0 3.2-2.7 5.7-6 5.7s-6-2.6-6-5.7 2.7-5.7 6-5.7z"></path><path d="M47 35.7h-.9c-3.1 0-6.1.9-8.7 2.6-2.5-3.8-6.9-6.3-11.8-6.3H15.4C7.6 32 1.3 38.4 1.3 46.2v7.9c0 1.6 1.3 2.9 2.9 2.9H59.9c1.7 0 3-1.4 3-3v-2.5c-.1-8.7-7.2-15.8-15.9-15.8zM33.3 53.5H4.8v-7.3c0-5.9 4.8-10.7 10.7-10.7h10.1c5.9 0 10.7 4.8 10.7 10.7v7.3h-3zm26 0H39.8v-7.3c0-1.6-.3-3.2-.8-4.7 2.1-1.5 4.5-2.3 7.1-2.3h.9c6.8 0 12.3 5.5 12.3 12.3v2z"></path></g></svg></template>

<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: "100%"
		},
		height: {
			type: [Number, String],
			default: "100%"
		},
		strokewidth: {
			type: [Number, String],
			default: 1
		},
		fill: {
			type: String,
			default: "currentColor"
		},
		secondaryfill: {
			type: String
		}
	},
	computed: {
		_secondaryfill: function () {
			return this.secondaryfill || this.fill
		}
	}
}
</script>